import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { NextPageButton } from "components/Button/NextPageButton";
import { DatePicker } from "components/Form/DatePicker/DatePicker";
import { Select } from "components/Form/Select/Select";
import { Icon } from "components/Icon/Icon";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  GetWebhookEvents,
  WebhookCallStatus,
  WebhookCallStatusKnownValues,
  WebhookEventTypeKnownValue,
  WebhookEventTypeKnownValues,
} from "generated/copilot-api-developer";
import { useTranslation } from "i18n";
import { isKnownValue } from "utils/enum";

import { webhookEventTypeString } from "./types";
import { WebhookEvent } from "./WebhookEvent";

gql`
  # schema = COPILOT_API_DEVELOPER
  query GetWebhookEvents($filters: WebhookEventsFilter, $cursor: String) {
    webhookEvents(
      page: { numberOfItems: 20, cursor: $cursor }
      filters: $filters
    ) {
      nextCursor
      hasMore
      data {
        ...WebhookEvent
      }
    }
  }

  fragment WebhookEvent on WebhookEvent {
    uuid
    type
    createdAt
    status
  }
`;

export const WebhookEvents = () => {
  const t = useTranslation();
  const [from, setFrom] = useState<ISOString>();
  const [to, setTo] = useState<ISOString>();
  const [type, setType] = useState<WebhookEventTypeKnownValue>();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-16">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("developers.webhook_events.webhook_events.webhook_events")}
        </h1>
        <div>
          {t(
            "webhook_events.below_are_the_events_you_can_receive_with_a_webhook",
          )}
        </div>
      </div>
      <div className="flex space-x-16 rounded shadow-sm-outlined border bg-white p-16">
        <DatePicker<true>
          wrapperClassName="flex-1"
          label="From"
          value={from}
          onChange={(v) => {
            setFrom(v);
          }}
        />
        <DatePicker<true>
          wrapperClassName="flex-1"
          label="To"
          value={to}
          onChange={(v) => {
            setTo(v);
          }}
        />
        <div className="flex-1">
          <Select
            value={type}
            name="type"
            label={t("developers.webhook_events.webhook_events.type")}
            options={WebhookEventTypeKnownValues}
            getOptionLabel={(it) => webhookEventTypeString(it)}
            onChange={(v) => setType(v)}
          />
        </div>
      </div>
      <div className="flex items-center" />
      <PaginatedQuery
        query={GetWebhookEvents}
        variables={{
          filters: { createdAtAfter: from, createdAtBefore: to, type },
        }}
        pollInterval={60_000}
      >
        {({ data }, utils) => (
          <>
            <Table
              elements={data}
              fieldHeaders={[
                t("developers.webhook_events.webhook_events.created_at"),
                t("developers.webhook_events.webhook_events.type"),
                t("developers.webhook_events.webhook_events.status"),
              ]}
              fields={(event) => [
                `${event.createdAt.format("date")} ${event.createdAt.format(
                  "time",
                )}`,
                isKnownValue(event.type, WebhookEventTypeKnownValues)
                  ? webhookEventTypeString(event.type)
                  : "",
                <div key={event.uuid} className="flex justify-start pl-12">
                  <Status status={event.status} />
                </div>,
              ]}
              doRowAction={(event) =>
                event.status === "FAILED"
                  ? {
                      mode: "expand",
                      expand: (e) => <WebhookEvent uuid={e.uuid} />,
                    }
                  : { mode: "none" }
              }
            />
            <NextPageButton {...utils} />
          </>
        )}
      </PaginatedQuery>
    </Background>
  );
};

const Status = ({ status }: { status: WebhookCallStatus }) => {
  const t = useTranslation();

  if (!isKnownValue(status, WebhookCallStatusKnownValues)) return null;
  switch (status) {
    case "ATTEMPTING":
      return (
        <TooltipWrapper
          label={t("developers.webhook_events.webhook_events.attempting")}
        >
          <Icon name="change" color="orange" />
        </TooltipWrapper>
      );
    case "FAILED":
      return (
        <TooltipWrapper
          label={t(
            "webhook_events.we_failed_to_deliver_this_event_to_all_webhooks",
          )}
        >
          <Icon name="close" color="red" />
        </TooltipWrapper>
      );
    case "SUCCEEDED":
      return (
        <TooltipWrapper
          label={t("webhook_events.the_event_reached_all_webhooks")}
        >
          <Icon name="check" color="green" />
        </TooltipWrapper>
      );
  }
};
