// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type EmptyObject = "EMPTY" | "FUTURE_VALUE";
export type EmptyObjectKnownValue = "EMPTY";
export const EmptyObjectKnownValues: EmptyObjectKnownValue[] = [ "EMPTY" ];
export type RegionForEmailReason = "ACCOUNT_EXISTS" | "DOMAIN_IS_OWNED" | "FUTURE_VALUE";
export type RegionForEmailReasonKnownValue = "ACCOUNT_EXISTS" | "DOMAIN_IS_OWNED";
export const RegionForEmailReasonKnownValues: RegionForEmailReasonKnownValue[] = [ "ACCOUNT_EXISTS","DOMAIN_IS_OWNED" ];
export type NablaProduct = "ASSISTANT" | "CORE_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "ASSISTANT" | "CORE_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "ASSISTANT","CORE_API" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH" ];
export type TargetFrontend = "COPILOT_WEB_APP" | "COPILOT_CHROME_EXTENSION" | "COPILOT_MOBILE_APP" | "FUTURE_VALUE";
export type TargetFrontendKnownValue = "COPILOT_WEB_APP" | "COPILOT_CHROME_EXTENSION" | "COPILOT_MOBILE_APP";
export const TargetFrontendKnownValues: TargetFrontendKnownValue[] = [ "COPILOT_WEB_APP","COPILOT_CHROME_EXTENSION","COPILOT_MOBILE_APP" ];
export type MfaMethodKind = "TOTP" | "SMS" | "FUTURE_VALUE";
export type MfaMethodKindKnownValue = "TOTP" | "SMS";
export const MfaMethodKindKnownValues: MfaMethodKindKnownValue[] = [ "TOTP","SMS" ];
export type NablaRegion = "EU" | "US" | "FUTURE_VALUE";
export type NablaRegionKnownValue = "EU" | "US";
export const NablaRegionKnownValues: NablaRegionKnownValue[] = [ "EU","US" ];



export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "VerificationCodeIntent": [
    "VerificationCodeIntentNewAccount",
    "VerificationCodeIntentUserInitiatedReset",
    "VerificationCodeIntentSuperuserInitiatedReset"
  ],
  "LoginResponse": [
    "LoginResponseSuccess",
    "LoginResponseMfaRequired"
  ],
  "MfaState": [
    "NotSetupMfaState",
    "SetupMfaState"
  ],
  "MfaMethod": [
    "TotpMfaMethod",
    "SmsMfaMethod"
  ]
};
export type PossibleTypes = {
  VerificationCodeIntent: ("VerificationCodeIntentNewAccount" | "VerificationCodeIntentUserInitiatedReset" | "VerificationCodeIntentSuperuserInitiatedReset")
  LoginResponse: ("LoginResponseSuccess" | "LoginResponseMfaRequired")
  MfaState: ("NotSetupMfaState" | "SetupMfaState")
  MfaMethod: ("TotpMfaMethod" | "SmsMfaMethod")
};

const LoginResponseFragmentDocument = gql`
fragment LoginResponse on LoginResponse {
  ... on LoginResponseSuccess {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
  ... on LoginResponseMfaRequired {
    mfaState {
      ... on SetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
          }
          ... on SmsMfaMethod {
            isSetup
            phone
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
  }
}

`
export const LoginResponseFragmentProps: Fragment<LoginResponseFragment> = {
  document: LoginResponseFragmentDocument,
  fragmentName: "LoginResponse",
  entityName: "LoginResponse",
  __do_not_use_Data: null
}
export type LoginResponseFragment = ({ __typename: "LoginResponseSuccess"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } } | { __typename: "LoginResponseMfaRequired"; mfaState: ({ __typename: "SetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean } | { __typename: "SmsMfaMethod"; isSetup: boolean; phone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" }) } | { __typename: "FutureValue" })

export type CreateAccountData = { __typename: "CreateAccountOutput"; _: Maybe<EmptyObject> }
export type CreateAccountVariables = { email: string; region: NablaRegion; timezone: TimeZone; frontendData: string }
export const CreateAccount: Mutation<CreateAccountData, CreateAccountVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation CreateAccount($email: String!, $region: NablaRegion!, $timezone: TimeZone!, $frontendData: String!) {
  createAccount(
    email: $email
    region: $region
    locale: ENGLISH
    timezone: $timezone
    accountCreationEmail: {chooseCredentials: {product: CORE_API, frontendData: $frontendData}}
  ) {
    _
  }
}

`,
  endpointName: "createAccount",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type StartAccountPasswordResetData = { __typename: "StartUserInitiatedAccountPasswordResetOutput"; _: Maybe<EmptyObject> }
export type StartAccountPasswordResetVariables = { email: string }
export const StartAccountPasswordReset: Mutation<StartAccountPasswordResetData, StartAccountPasswordResetVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation StartAccountPasswordReset($email: String!) {
  startUserInitiatedAccountPasswordReset(email: $email) {
    _
  }
}

`,
  endpointName: "startUserInitiatedAccountPasswordReset",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type RequestLoginCodeData = { __typename: "RequestLoginCodeOutput"; _: Maybe<EmptyObject> }
export type RequestLoginCodeVariables = { email: string; targetFrontend: TargetFrontend; includeMagicLink?: Maybe<boolean> }
export const RequestLoginCode: Mutation<RequestLoginCodeData, RequestLoginCodeVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation RequestLoginCode($email: String!, $targetFrontend: TargetFrontend!, $includeMagicLink: Boolean) {
  requestLoginCode(
    email: $email
    targetFrontend: $targetFrontend
    includeMagicLink: $includeMagicLink
  ) {
    _
  }
}

`,
  endpointName: "requestLoginCode",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type RedeemLoginCodeData = { __typename: "RedeemLoginCodeOutput"; loginResponse: LoginResponseFragment }
export type RedeemLoginCodeVariables = { email: string; code: string; mfaCode?: Maybe<string> }
export const RedeemLoginCode: Mutation<RedeemLoginCodeData, RedeemLoginCodeVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation RedeemLoginCode($email: String!, $code: String!, $mfaCode: String) {
  redeemLoginCode(email: $email, code: $code, mfaCode: $mfaCode) {
    loginResponse {
      ...LoginResponse
    }
  }
}
${LoginResponseFragmentDocument}
`,
  endpointName: "redeemLoginCode",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type IsEmailVerificationCodeValidData = { __typename: "IsEmailVerificationCodeValidOutput"; email: string; locale: SupportedLocale; mfaState: ({ __typename: "SetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean } | { __typename: "SmsMfaMethod"; isSetup: boolean; phone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" }); intent: Maybe<({ __typename: "VerificationCodeIntentNewAccount"; product: NablaProduct; frontendData: Maybe<string> } | { __typename: "VerificationCodeIntentUserInitiatedReset"; _: Maybe<EmptyObject> } | { __typename: "VerificationCodeIntentSuperuserInitiatedReset"; _: Maybe<EmptyObject> } | { __typename: "FutureValue" })> }
export type IsEmailVerificationCodeValidVariables = { email: string; emailVerificationToken: string }
export const IsEmailVerificationCodeValid: Query<IsEmailVerificationCodeValidData, IsEmailVerificationCodeValidVariables, true, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
query IsEmailVerificationCodeValid($email: String!, $emailVerificationToken: String!) {
  isEmailVerificationCodeValid(
    email: $email
    emailVerificationToken: $emailVerificationToken
  ) {
    email
    locale
    mfaState {
      ... on SetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
          }
          ... on SmsMfaMethod {
            isSetup
            phone
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
    intent {
      ... on VerificationCodeIntentNewAccount {
        product
        frontendData
      }
      ... on VerificationCodeIntentUserInitiatedReset {
        _
      }
      ... on VerificationCodeIntentSuperuserInitiatedReset {
        _
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type ResendEmailVerificationCodeAfterExpirationData = { __typename: "ResendEmailVerificationCodeAfterExpirationOutput"; _: Maybe<EmptyObject> }
export type ResendEmailVerificationCodeAfterExpirationVariables = { email: string; emailVerificationToken: string }
export const ResendEmailVerificationCodeAfterExpiration: Mutation<ResendEmailVerificationCodeAfterExpirationData, ResendEmailVerificationCodeAfterExpirationVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation ResendEmailVerificationCodeAfterExpiration($email: String!, $emailVerificationToken: String!) {
  resendEmailVerificationCodeAfterExpiration(
    email: $email
    emailVerificationToken: $emailVerificationToken
  ) {
    _
  }
}

`,
  endpointName: "resendEmailVerificationCodeAfterExpiration",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type ChooseAccountCredentialsUnauthenticatedData = { __typename: "ChooseAccountCredentialsOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type ChooseAccountCredentialsUnauthenticatedVariables = { email: string; emailVerificationToken: string; newPassword: string; verifyMfaCode?: Maybe<string> }
export const ChooseAccountCredentialsUnauthenticated: Mutation<ChooseAccountCredentialsUnauthenticatedData, ChooseAccountCredentialsUnauthenticatedVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation ChooseAccountCredentialsUnauthenticated($email: String!, $emailVerificationToken: String!, $newPassword: String!, $verifyMfaCode: String) {
  chooseAccountCredentials(
    email: $email
    emailVerificationToken: $emailVerificationToken
    mfa: {verify: {code: $verifyMfaCode}}
    newPassword: $newPassword
  ) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "chooseAccountCredentials",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SendMfaCodeBySmsData = { __typename: "SendMfaCodeBySmsOutput"; _: Maybe<EmptyObject> }
export type SendMfaCodeBySmsVariables = { accountEmail: string; phoneNumber: string; antiAbuseToken: string }
export const SendMfaCodeBySms: Mutation<SendMfaCodeBySmsData, SendMfaCodeBySmsVariables, "UNAUTHENTICATED_ACCOUNT"> = {
  schemaType: "UNAUTHENTICATED_ACCOUNT",
  document: gql`
mutation SendMfaCodeBySms($accountEmail: String!, $phoneNumber: String!, $antiAbuseToken: String!) {
  sendMfaCodeBySms(
    accountEmail: $accountEmail
    phone: $phoneNumber
    mfaBySmsAntiAbuseToken: $antiAbuseToken
  ) {
    _
  }
}

`,
  endpointName: "sendMfaCodeBySms",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}