import { WebhookEventTypeKnownValue } from "generated/copilot-api-developer";

export const webhookEventTypeString = (value: WebhookEventTypeKnownValue) => {
  switch (value) {
    case "GENERATE_NOTE_ASYNC_SUCCEEDED":
      return "generate_note_async.succeeded";
    case "GENERATE_NOTE_ASYNC_FAILED":
      return "generate_note_async.failed";
    case "TRANSCRIBE_ASYNC_SUCCEEDED":
      return "transcribe_async.succeeded";
    case "TRANSCRIBE_ASYNC_FAILED":
      return "transcribe_async.failed";
  }
};
